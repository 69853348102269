import React from "react";
import styled from "styled-components";

import Icons from "./images/icons";

export default function Overview(props) {
  //   console.log(props.type);
  return (
    <FilterWrap>
      <h4>Overview</h4>

      <div className="filters">
        <div
          className="filterItem"
          onClick={() => {
            props.onFilter({ distance: !props.type.distance });
          }}
        >
          <span className="name">
            <img src={Icons.distance.km1} width={23} height={23} />
            Distance Markers
          </span>
          <span
            className={props.type.distance ? "selected true" : "selected"}
          ></span>
        </div>
        <div
          className="filterItem"
          onClick={() => {
            props.onFilter({ sanitary: !props.type.sanitary });
          }}
        >
          <span className="name">
            <img src={Icons.sanitary.simple} width={23} height={23} />
            Sanitary
          </span>
          <span
            className={props.type.sanitary ? "selected true" : "selected"}
          ></span>
        </div>
        <div
          className="filterItem"
          onClick={() => {
            props.onFilter({ medical: !props.type.medical });
          }}
        >
          <span className="name">
            {" "}
            <img src={Icons.medical.point} width={23} height={23} />
            Medical
          </span>
          <span
            className={props.type.medical ? "selected true" : "selected"}
          ></span>
        </div>
      </div>
    </FilterWrap>
  );
}

const FilterWrap = styled.div`
  position: absolute;
  top: 100px;
  right: auto;
  bottom: auto;
  left: 20px;
  width: 250px;
  z-index: 100;
  background: #fff;
  box-shadow: 9px 5px 10px 1px rgba(0, 0, 0, 0.1);

  h4 {
    padding: 0 0.5rem;
    margin: 0.5rem;
  }

  .filterItem {
    height: 35px;
    position: relative;
    /* padding-left: 45px; */
    display: table;
    width: 100%;
    cursor: pointer;

    span {
      display: table-cell;
      vertical-align: middle;
    }

    .name {
      font-weight: 400;
      font-size: 0.91rem;

      img {
        margin: 0 11px;
        vertical-align: middle;
      }
    }

    .selected {
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 40px;
      background-color: rgba(0, 0, 0, 0.03);

      &.true:after {
        transform-origin: left top;
        border-right: 2px solid #3d3d3d;
        border-top: 2px solid #3d3d3d;
        content: "";
        display: block;
        height: 11px;
        width: 6px;
        position: absolute;
        top: 50%;
        left: 12px;
        transform: scaleX(-1) rotate(135deg);
      }
    }
  }

  .filterItem:nth-child(odd) {
    background-color: #f2f2f2;
  }
`;
