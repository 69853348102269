import React from "react";
import styled from "styled-components";
import Logo from "../images/logo.png";
import { Button } from "@mui/material";

export default function Header(props) {
  return (
    <HeaderWrap>
      <a href="https://bucuresti10km.ro/" target="_blank">
        <img src={Logo} />
      </a>
      <div className="mapButtons">
        <a href="https://bucuresti10km.ro/" target="_blank">
          Inapoi la site
        </a>
        <span
          className={props.race === "line_5k" ? "active" : ""}
          onClick={() => {
            props.onRace("line_5k");
          }}
        >
          Harta 5KM
        </span>
        <span
          className={props.race === "line_10k" ? "active" : ""}
          onClick={() => {
            props.onRace("line_10k");
          }}
        >
          Harta 10KM
        </span>
      </div>
    </HeaderWrap>
  );
}

const HeaderWrap = styled.header`
  background-color: #3d3d3d;
  height: 80px;
  padding: 0 20px;
  position: relative;
  z-index: 2;
  overflow: hidden;
  display: flex;
  align-items: center;
  box-shadow: 0px 1px 10px 2px rgba(0, 0, 0, 0.3);

  .mapButtons {
    margin-left: 100px;

    span,
    a {
      font-size: 20px;
      color: #fff;
      cursor: pointer;
      opacity: 0.6;
      padding: 0 2rem;
      font-weight: 700;
      text-decoration: none;

      &.active {
        opacity: 1;
      }
    }
  }

  img {
    height: 70px;
  }
`;
