const icons = {
  distance: {
    km1: require("./distance/1km.svg").default,
    km2: require("./distance/2km.svg").default,
    km3: require("./distance/3km.svg").default,
    km4: require("./distance/4km.svg").default,
    km5: require("./distance/5km.svg").default,
    km6: require("./distance/6km.svg").default,
    km7: require("./distance/7km.svg").default,
    km8: require("./distance/8km.svg").default,
    km9: require("./distance/9km.svg").default,
    km10: require("./distance/10km.svg").default,
  },
  global: {
    start: require("./start.svg").default,
    finish: require("./finish.svg").default,
  },
  medical: {
    point: require("./medicalpoint.svg").default,
  },

  sanitary: {
    simple: require("./wc_fb.svg").default,
    all: require("./wc_fbw.svg").default,
  },
};

export default icons;
