import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Header from "./components/header";
import styled from "styled-components";
import Maps from "./Maps";

export default function App() {
  const [race, setRace] = React.useState("line_5k");
  return (
    <>
      <Header race={race} onRace={(e) => setRace(e)} />
      <Content>
        <div className="map_container">
          <Maps race={race} />
        </div>
      </Content>
    </>
  );
}

const Content = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: #e5e3df;
  overflow: hidden;

  .map_container {
    height: calc(100vh - 80px);
    top: 80px;
    position: absolute;
    width: 100%;
  }
`;
