import React, { useEffect } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  Polyline,
  Marker,
  OverlayView,
} from "@react-google-maps/api";
import customMapStyles from "./customMapStyles";
import XMLParser from "react-xml-parser";
import line_5k from "./maps/5k.gpx";
import line_10k from "./maps/10k.gpx";
import axios from "axios";

import Icons from "./images/icons";
import Points from "./maps/points";
import bikeIcon from "./images/bike_icon.png";
import Overview from "./Overview";

const containerStyle = {
  width: "100%",
  height: "100%",
};

const center = {
  lat: 44.429292703069,
  lng: 26.096719974899276,
};

const getPixelPositionOffset = (width, height) => ({
  x: -(width / 2),
  y: -(height / 2),
});

const Markers = ({ places }) => {
  return places.map((place) => {
    if (place.onMap === false || !place.onMap) return false;

    return (
      <Marker
        key={place.name}
        id={place.name}
        // icon=" "
        icon={{
          // path: google.maps.SymbolPath.CIRCLE,
          // url: require("./images/start_64.png"),
          url: place.icon,
          fillColor: "#EB00FF",
          scaledSize: new google.maps.Size(50, 50),
          // anchor: new google.maps.Point(25, 32),
          anchor: new google.maps.Point(25, 50),
          scale: 10,
        }}
        // draggable={true}
        // icon={"http://maps.google.com/mapfiles/ms/icons/blue.png"}
        position={{ lat: place.lat, lng: place.lng }}
        onDragEnd={(e) => {
          console.log("new position", e.latLng.toJSON());
        }}
      ></Marker>
    );
  });
};

function Maps(props) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyA7sBxFCI4XKTFZthxEnobKzrUa__d5mho",
  });

  const [map, setMap] = React.useState(null);
  const [line, setLine] = React.useState([]);

  const [type, setType] = React.useState({
    global: true,
    distance: true,
    sanitary: true,
    medical: true,
  });
  const [markers, setMarkers] = React.useState(Points.point_5k);

  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(center);
    markers.map((item) => {
      bounds.extend({ lat: item.lat, lng: item.lng });
      return item.id;
    });

    map.fitBounds(bounds);

    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  // const onCenterChanged = React.useCallback(function callback(map1) {
  //   if (map) console.log(map.center.toJSON());
  // }, []);

  // console.log(props.mapStyles);

  const handleFilter = (filter) => {
    // console.log("handleFilter", filter);

    setType({ ...type, ...filter });
  };

  useEffect(() => {
    // let newmarkers = markers.map(
    //   (marker) => marker.onMap === type[marker.type]
    // );

    setMarkers(
      markers.map((item) => {
        item.onMap = type[item.type];
        return item;
      })
    );
  }, [type]);

  useEffect(() => {
    let url = line_5k;
    if (props.race === "line_10k") {
      url = line_10k;
      setMarkers(Points.point_10k);
    } else if (props.race === "line_5k") {
      url = line_5k;
      setMarkers(Points.point_5k);
    }

    setType({
      global: true,
      distance: true,
      sanitary: true,
      medical: true,
    });

    axios
      .get(url, {
        "Content-Type": "application/xml; charset=utf-8",
      })
      .then((response) => {
        // console.log("Your xml file as string", response.data);

        var points = [];
        var xml = new XMLParser().parseFromString(response.data); // Assume xmlText contains the example XML
        // console.log(xml);
        xml.children.map((item) => {
          if (item.name === "wpt") {
            points.push({
              lat: Number(item.attributes.lat),
              lng: Number(item.attributes.lon),
            });
          }
        });
        // console.log(points[0]);
        setLine(points);
      });
  }, [props.race]);

  return isLoaded ? (
    <>
      <Overview type={type} onFilter={handleFilter} />
      <GoogleMap
        mapContainerStyle={containerStyle}
        // mapStyles={props.mapStyles}
        center={center}
        zoom={16}
        onLoad={onLoad}
        onUnmount={onUnmount}
        // streetView={false}
        // onCenterChanged={onCenterChanged}
        options={{ styles: props.mapStyles }}
      >
        {/* Child components, such as markers, info windows, etc. */}
        <></>

        <Polyline
          path={line}
          options={{
            geodesic: true,
            strokeColor: "red",
            strokeOpacity: 1.0,
            strokeWeight: 4,
          }}
        />

        <Markers places={markers} />
      </GoogleMap>
    </>
  ) : (
    <></>
  );
}

Maps.defaultProps = {
  // The style is copy from https://snazzymaps.com/style/2/midnight-commander
  mapStyles: customMapStyles,
};

export default React.memo(Maps);
