import Icons from "../images/icons";
const m_points = {
  point_5k: [
    {
      lat: 44.42682367467617,
      lng: 26.09980066507,
      public_name: "1km",
      name: "distance_1km",
      onMap: true,
      type: "distance",
      icon: Icons.distance.km1,
    },

    {
      lat: 44.42770479172409,
      lng: 26.103909809280815,
      public_name: "2km",
      name: "distance_2km",
      onMap: true,
      type: "distance",
      icon: Icons.distance.km2,
    },
    {
      lat: 44.43129137567521,
      lng: 26.094474803794803,
      public_name: "3km",
      name: "distance_3km",
      onMap: true,
      type: "distance",
      icon: Icons.distance.km3,
    },
    {
      lat: 44.43301707142114,
      lng: 26.090046476711215,
      public_name: "4km",
      name: "distance_4km",
      onMap: true,
      type: "distance",
      icon: Icons.distance.km4,
    },
    {
      lat: 44.42661009756574,
      lng: 26.10417937128681,
      public_name: "Medical point",
      name: "medicalpoint1",
      onMap: true,
      type: "medical",
      icon: Icons.medical.point,
    },

    {
      lat: 44.42686509705239,
      lng: 26.09189619510311,
      public_name: "Medical point",
      name: "medicalpoint0",
      onMap: true,
      type: "medical",
      icon: Icons.medical.point,
    },

    {
      lat: 44.428014376707644,
      lng: 26.091349024464073,

      public_name: "Toilets",
      name: "sanitary0",
      onMap: true,
      type: "sanitary",
      icon: Icons.sanitary.all,
    },

    {
      lat: 44.42661991446807,
      lng: 26.101047892261924,
      public_name: "Toilets",
      name: "sanitary1",
      onMap: true,
      type: "sanitary",
      icon: Icons.sanitary.simple,
    },
    {
      lat: 44.43320117773995,
      lng: 26.087744135548057,
      public_name: "Toilets",
      name: "sanitary2",
      onMap: true,
      type: "sanitary",
      icon: Icons.sanitary.simple,
    },
    {
      lat: 44.42714475718203,
      lng: 26.09094132869381,
      public_name: " Start | 5k",
      name: "m1",
      onMap: true,
      type: "global",
      color: "#ff6600",
      icon: Icons.global.start,
    },
    {
      lat: 44.427320022951314,
      lng: 26.090965468574943,
      public_name: " Finish | 5k",
      name: "finish5k",
      onMap: true,
      type: "global",
      color: "#ff6600",
      icon: Icons.global.finish,
    },
  ],
  point_10k: [
    {
      lat: 44.432991693911895,
      lng: 26.09035057215828,
      public_name: "9km",
      name: "distance_9km",
      onMap: true,
      type: "distance",
      icon: Icons.distance.km9,
    },
    {
      lat: 44.4314862611824,
      lng: 26.094223681975784,
      public_name: "8km",
      name: "distance_8km",
      onMap: true,
      type: "distance",
      icon: Icons.distance.km8,
    },
    {
      lat: 44.42871279249281,
      lng: 26.102919403602066,
      public_name: "7km",
      name: "distance_7km",
      onMap: true,
      type: "distance",
      icon: Icons.distance.km7,
    },
    {
      lat: 44.42628877268524,
      lng: 26.114935699988784,
      public_name: "6km",
      name: "distance_6km",
      onMap: true,
      type: "distance",
      icon: Icons.distance.km6,
    },
    {
      lat: 44.42726088278475,
      lng: 26.130627963830413,
      public_name: "5km",
      name: "distance_5km",
      onMap: true,
      type: "distance",
      icon: Icons.distance.km5,
    },
    {
      lat: 44.42903985028957,
      lng: 26.133868072320404,
      public_name: "4km",
      name: "distance_4km",
      onMap: true,
      type: "distance",
      icon: Icons.distance.km4,
    },
    {
      lat: 44.42558051086114,
      lng: 26.126716632534446,
      public_name: "3km",
      name: "distance_3km",
      onMap: true,
      type: "distance",
      icon: Icons.distance.km3,
    },
    {
      lat: 44.42643866066589,
      lng: 26.108778018642845,
      public_name: "2km",
      name: "distance_2km",
      onMap: true,
      type: "distance",
      icon: Icons.distance.km2,
    },
    {
      lat: 44.42682367467617,
      lng: 26.09980066507,
      public_name: "1km",
      name: "distance_1km",
      onMap: true,
      type: "distance",
      icon: Icons.distance.km1,
    },
    {
      lat: 44.42637257591659,
      lng: 26.129628170420112,
      public_name: "Medical point",
      name: "medicalpoint2",
      onMap: true,
      type: "medical",
      icon: Icons.medical.point,
    },
    {
      lat: 44.42661009756574,
      lng: 26.10417937128681,
      public_name: "Medical point",
      name: "medicalpoint1",
      onMap: true,
      type: "medical",
      icon: Icons.medical.point,
    },

    {
      lat: 44.42686509705239,
      lng: 26.09189619510311,
      public_name: "Medical point",
      name: "medicalpoint0",
      onMap: true,
      type: "medical",
      icon: Icons.medical.point,
    },

    {
      lat: 44.428014376707644,
      lng: 26.091349024464073,

      public_name: "Toilets",
      name: "sanitary0",
      onMap: true,
      type: "sanitary",
      icon: Icons.sanitary.all,
    },

    {
      lat: 44.42661991446807,
      lng: 26.101047892261924,
      public_name: "Toilets",
      name: "sanitary1",
      onMap: true,
      type: "sanitary",
      icon: Icons.sanitary.simple,
    },
    {
      lat: 44.43320117773995,
      lng: 26.087744135548057,
      public_name: "Toilets",
      name: "sanitary2",
      onMap: true,
      type: "sanitary",
      icon: Icons.sanitary.simple,
    },
    {
      lat: 44.43179917795256,
      lng: 26.138427157093467,
      public_name: "Toilets",
      name: "sanitary3",
      onMap: true,
      type: "sanitary",
      icon: Icons.sanitary.simple,
    },
    {
      lat: 44.42582306396658,
      lng: 26.119029421497764,
      public_name: "Toilets",
      name: "sanitary4",
      onMap: true,
      type: "sanitary",
      icon: Icons.sanitary.simple,
    },
    {
      lat: 44.42714475718203,
      lng: 26.09094132869381,
      public_name: " Start | 5k",
      name: "m1",
      onMap: true,
      type: "global",
      color: "#ff6600",
      icon: Icons.global.start,
    },
    {
      lat: 44.427320022951314,
      lng: 26.090965468574943,
      public_name: " Finish | 5k",
      name: "finish5k",
      onMap: true,
      type: "global",
      color: "#ff6600",
      icon: Icons.global.finish,
    },
  ],
};

export default m_points;
